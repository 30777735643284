import React, { useState } from 'react';
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';

const Login = () => {
    const [user, setUser] = useState({
        email : "",
        password : "",
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const loginUser = async () => {
        setLoading(true);
        const apiUrl = process.env.REACT_APP_API_URL;

        try {
            const response = await axios.post(apiUrl+'/api/users/login',user);
            sessionStorage.setItem('token', response.data.token);

            //toast.success("Login success");

            // // Reset form
            // setUser({
            //     email:'',
            //     password:''
            // })
            
            navigate('/home');

        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    loginUser();
  };

  return (
    <div>
    <Header></Header>
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-3 rounded-xl bg-white shadow-lg">
        <h1 className="text-2xl font-bold text-center">Login</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="email"
              className="input input-bordered w-full"
              placeholder="you@example.com"
              value={user.email}
              onChange={(e) => setUser({...user , email: e.target.value})}
              required
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              type="password"
              className="input input-bordered w-full"
              placeholder="••••••••"
              value={user.password}
              onChange={(e) => setUser({...user , password: e.target.value})}
              required
            />
          </div>
          <div className="form-control mt-6">
            <button className="btn btn-primary w-full">Login</button>
          </div>
        </form>
        <p className="text-xs text-center sm:px-6">
          Don't have an account?{" "}
          <a href="/register" className="underline">
            Sign up
          </a>
        </p>
      </div>
    </div>
    </div>
  );
};

export default Login;
