import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Header =()=>{

    const location = useLocation();

    return(
    <nav className="navbar bg-base-100 shadow-lg sticky top-0 z-50" data-theme="aqua">
    <div class="navbar-start">
        <img src='/images/Logo.jpg' className="w-12 h-12 object-cover" alt="Advanced Search Filters" />
        <a href="/" className="btn btn-ghost normal-case text-3xl font-extrabold text-primary font-serif tracking-wide">Maidly</a>
    </div>
    {location.pathname == "/" && (
    <div class="navbar-center hidden lg:flex">
        <ul class="menu menu-horizontal px-1">
        <li><a href="#services" className="text-lg font-medium hover:text-primary transition-all">Services</a></li>
        <li><a href="#about" className="text-lg font-medium hover:text-primary transition-all">About Us</a></li>
        <li><a href="#contact" className="text-lg font-medium hover:text-primary transition-all">Contact</a></li>
        </ul>
    </div>
    )}
    {location.pathname != "/home" && (
    <div class="navbar-end hidden lg:flex">
        <ul class="menu menu-horizontal px-2">
    <li><a href="/login" class="btn btn-primary">Login</a></li>
    <li></li>
    <li><a href="/register" class="btn btn-primary">Register</a>
    </li>
    </ul>
    </div>
    )}
    </nav>
    );
};

export default Header;