import React, { useEffect, useRef,useState } from 'react';

const GoogleMap = ({sendToRegistration}) => {
  const googleMapRef = useRef(null); // Reference to the map container
  const googleMap = useRef(null);    // Reference to the Google Map instance
  const markerRef = useRef(null);    // Use `useRef` to store marker, without causing re-renders
  const [address, setAddress] = useState('');  // State to store the address
  const [latLng, setLatLng] = useState(null);  // State to store marker lat/lng


  // Function to load the Google Maps API script dynamically
  const loadGoogleMapsScript = (callback) => {
    const existingScript = document.getElementById('googleMapsScript');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAyr5npzHlR3kdLHVA6w8pkOT9iHFRhJ8E&callback=initMap`;
      script.id = 'googleMapsScript';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    } else {
      if (callback) callback();
    }
  };

  // Function to initialize the map
  const initMap = (position) => {
    const { latitude, longitude } = position.coords;

    // Initialize the map with the user's current location
    googleMap.current = new window.google.maps.Map(googleMapRef.current, {
      center: { lat: latitude, lng: longitude },  
      zoom: 13, // Set zoom level to show more detail
    });

    // Add a click event listener to place a new marker at the clicked location
    googleMap.current.addListener('click', (e) => {
      placeMarker(e.latLng);
    });
  };

  // Function to handle errors with geolocation (e.g., if permission is denied)
  const handleLocationError = () => {
    // Fallback location (Example: Default to Chennai)
    const fallbackLocation = { lat: 13.0524, lng: 80.1612 };

    googleMap.current = new window.google.maps.Map(googleMapRef.current, {
      center: fallbackLocation,
      zoom: 13,
    });

    // Place a marker at the fallback location
    new window.google.maps.Marker({
      position: fallbackLocation,
      map: googleMap.current,
      title: 'Chennai (Fallback)',
    });

    // Add a click event listener to place a new marker at the clicked location
    googleMap.current.addListener('click', (e) => {
      placeMarker(e.latLng);
    });
  };

  // Function to place a marker on the map
  const placeMarker = (location) => {
    if (markerRef.current) {
        markerRef.current.setPosition(location); // Move existing marker
    } else {
        markerRef.current = new window.google.maps.Marker({
        position: location,
        map: googleMap.current,
        title:"Selected Location"
      });
    }

    // Update the marker's lat/lng in state
    setLatLng(location.toJSON());

    // Call function to get the address using Geocoder
    getAddressFromLatLng(location);

    //alert(location);    
  };

   // Function to get address from lat/lng using Geocoder
   const getAddressFromLatLng = (location) => {
    const geocoder = new window.google.maps.Geocoder();
    
    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          setAddress(results[0].formatted_address); // Set the address in state
          sendToRegistration(results[0].formatted_address,location.toJSON());
        } else {
          setAddress('No address found');
        }
      } else {
        setAddress('Geocoder failed: ' + status);
      }
    });
  };

  // Load Google Maps script and initialize the map with current location
  useEffect(() => {
    loadGoogleMapsScript(() => {
      // Use the Geolocation API to get the user's current location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(initMap, handleLocationError);
      } else {
        handleLocationError(); // Fallback if geolocation is not supported
      }
    });
  }, []);

  return (
    <div>
      <div
        ref={googleMapRef}
        style={{ width: '100%', height: '500px' }} // Set map size
      ></div>
    </div>
  );
};

export default GoogleMap;
