import React,{ useEffect, useState } from 'react';
import Header from './header/header';
import axios from 'axios';

function Dashboard()
{
    const [status,setStatus]=useState('');
    const [errors, setErrors] = useState({});
    const apiUrl = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject:'Enquiry ',
        message:''
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

    const handleSubmit = (e) => {
        e.preventDefault();

        const sendEmail = async () => {
        try {
            const response = await axios.post(apiUrl+'/api/users/sendEmail',formData);
            setStatus(response.data.message);

            // Reset form
            setFormData({
                name: '',
                email: '',
                subject:'',
                message:''
            });

            setErrors({});
          } catch (error) {
            console.error('Error:', error);
            setStatus('Failed to send email.');
          }
        };

        sendEmail();
    };

    return(
    <div>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Service Business</title>
    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet" />
    <script src="https://cdn.jsdelivr.net/npm/daisyui"></script>

    <Header></Header>

    {/* <!-- Hero Section --> */}
    <section class="hero min-h-screen bg-base-200">
    <div class="hero-content text-center">
        <div>
        <h1 class="text-5xl font-bold">Maidly – Finding the Right Help, Made Simple</h1>
        <p class="py-6">Maidly is an intuitive application designed to help users easily search, compare, and hire domestic workers based on their specific needs.</p>
        <p>Whether you're looking for a full-time live-in maid, a part-time cleaner, or a specialized nanny or housekeeper, Maidly provides a detailed directory of qualified professionals from various categories, making it easy to find the right help for your home or business.</p>
        </div>
    </div>
    </section>

    {/* <!-- Services Section --> */}
    <section id="services" class="py-12 bg-base-100">
    <h2 class="text-4xl font-bold text-center mb-8">Key Features</h2>
    <div class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div class="card shadow-lg">
        <figure><img src='/images/AdvancedSearch.png' className="w-32 h-32 object-cover" alt="Advanced Search Filters" /></figure>
        <div class="card-body">
            <h3 class="card-title">Advanced Search Filters</h3>
            <p>Users can search for maids based on location, type of service, availability (full-time/part-time), and experience level.
            Filter options for specialized roles like nanny, cook, laundress, or house manager.</p>
        </div>
        </div>
        <div class="card shadow-lg">
        <figure><img src="/images/Maid_Profile.png" className="w-32 h-32 object-cover" alt="Service 2" /></figure>
        <div class="card-body">
            <h3 class="card-title">Detailed profiles for each maid</h3>
            <p>Type of service: Live-in/Live-out, Full-time/Part-time, Cleaning, Nannying, Cooking, Laundry, etc.
                Experience and skills.
                Certifications or qualifications (e.g., childcare, cooking).
                Reviews and ratings from previous employers.
            </p>
        </div>
        </div>
        {/* <div class="card shadow-lg">
        <figure><img src="../assets/images/Booking_Scheduling.jpg" alt="Service 3" /></figure>
        <div class="card-body">
            <h3 class="card-title">Booking & Scheduling</h3>
            <p>Easily schedule a consultation or trial with your preferred maid based on availability.
            Option for one-time service or ongoing contracts.</p>
        </div>
        </div> */}
        <div class="card shadow-lg">
        <figure><img src="/images/Comparison.jpg" className="w-32 h-32 object-cover" alt="Service 3" /></figure>
        <div class="card-body">
            <h3 class="card-title">Comparison Tool</h3>
            <p>Side-by-side comparison of maids' profiles, skills, and hourly/daily rates.</p>
        </div>
        </div>
    </div>
    </section>

    {/* <!-- About Us Section --> */}
    <section id="about" class="bg-base-200 py-12">
    <div class="container mx-auto">
        <h2 class="text-4xl font-bold text-center mb-8">About Us</h2>
        <div class="flex flex-col md:flex-row items-center">
        <div>
            <img src="/images/AboutUs.jpg" className="w-152 h-52 object-cover" alt="About Us" class="rounded-lg shadow-2xl" />
        </div>
        <div class="w-full md:w-1/2 px-4">
            <h3 class="text-3xl font-bold">We’re Dedicated to Your Success</h3>
            <p class="py-4">At Maidly, our team is dedicated to simplifying your search for trusted and reliable household help. We are a group of passionate professionals driven by the mission to connect households with skilled and dependable maids, ensuring both convenience and safety. Our team consists of experts from diverse fields, including technology, customer service, and human resources, all working together to create a seamless and user-friendly platform.</p>
            <a href="#contact" class="btn btn-primary">Get in Touch</a>
        </div>
        </div>
    </div>
    </section>

    {/* <!-- Contact Section --> */}
    <section id="contact" class="py-12 bg-base-100">
    <div class="container mx-auto">
        <h2 class="text-4xl font-bold text-center mb-8">Contact Us</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
            <p class="text-lg">Reach out to us with any questions or inquiries. We are here to help you get started with our services!</p>
            <ul class="list-disc pl-5 mt-4">
            <li>Email: elberithcorp@gmail.com</li>
            <li>Address: Chennai, INDIA</li>
            </ul>
        </div>
        <form onSubmit={handleSubmit} class="space-y-4">
            <div class="form-control">
            <label class="label">Your Name</label>
            <input type="text" placeholder="Name" name="name" onChange={handleChange} value={formData.name} class="input input-bordered w-full" />
            </div>
            <div class="form-control">
            <label class="label">Your Email</label>
            <input type="email" placeholder="Email" name="email" onChange={handleChange} value={formData.email} class="input input-bordered w-full" />
            </div>
            <div class="form-control">
            <label class="label">Your Message</label>
            <textarea placeholder="Message" name="message" onChange={handleChange} value={formData.message} class="textarea textarea-bordered w-full"></textarea>
            </div>
            <div>
            <button type="submit" class="btn btn-primary">Send Message</button>
            </div>
        </form>
        </div>
    </div>
    </section>

    <footer class="footer footer-center p-10 bg-base-200 text-base-content rounded">
    <div>
        <p>Copyright © 2024 - All right reserved by Elberith Corp</p>
    </div>
    </footer>

    </div>
    );
}

export default Dashboard;