import React, { useState, useEffect } from "react";
import { GoogleMap,LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import {jwtDecode} from 'jwt-decode';
import axios from "axios";
import Header from "../header/header";
import { useNavigate } from 'react-router-dom';

// Your Google Maps API Key
const GOOGLE_MAPS_API_KEY = "AIzaSyAyr5npzHlR3kdLHVA6w8pkOT9iHFRhJ8E";

// Container styling for the map
const containerStyle = {
  width: "100vw",
  height: "80vh",
};

// Initial location - the center of the map (latitude and longitude of Chennai)
const center = {
  lat: 12.969339,
  lng: 80.204415,
};


const Home = () => {
  const [location, setLocation] = useState(center);
  const [email, setEmail] = useState(null);
  const [users, setUsers] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedPosition, setselectedPosition] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const offsetLat = 0.015;
  const navigate = useNavigate();
  var decToken = null;

  useEffect(() => {

    const token = sessionStorage.getItem('token');
    if(token === null)
    {
        navigate('/login');
        return;
    }
    else
    {
        decToken = jwtDecode(token);
        setEmail(decToken?.email);
        console.log(decToken?.email);
    }

    const getUser = async () => {
        try {
            const response = await axios.post(apiUrl+'/api/users/getuser',{email:decToken.email});
 
            const positions = response.data.users.map((usr)=>{
                const [lat, lng] = usr.position
                .replaceAll("\"","")
                .replace("{lat:","")
                .replace("lng:","")
                .replace("}","")
                .split(",").map(coord => parseFloat(coord.trim()));
                return { lat, lng };
            });

            for (let i = 0; i < response.data.users.length; i++) {
                response.data.users[i].position = positions[i];
              }

            setUsers(response.data.users);

            console.log("logged in successfully" , response.data.users);
        }
        catch(error)
        {
            console.log(error);
        }
    }

    getUser();

  }, []);

  function initMap(){
    const point = new window.google.maps.Point(17, -12);
    console.log(point);
}

  return (
    <div>
        <Header></Header>
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={location}
        zoom={15}
      >
       {users?.map((user) => (
          <Marker
            key={user._id}
            position={user.position}
            onClick={() => setSelectedMarker({
                position: user.position,
                title: user.address,
                description: user.phonenumber
              })}
            label={{
              text: user.fullName,
              fontSize: "16px",
              color: "black", // Optional: You can change label color here
            }}
            // // Optional: Custom red marker icon
            // icon={{
            //   url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // URL for red marker icon
            //   labelOrigin: {initMap}
            // }}
          />    
        ))}

        {/* Display the InfoWindow if a marker is selected */}
        {selectedMarker && (
          <InfoWindow
            position={selectedMarker.position}
            onCloseClick={() => setSelectedMarker(null)} // Close the InfoWindow
          >
            <div style={{ width: '200px', height: '100px', overflow: 'auto' }}>
              <h1><b>{selectedMarker.title}</b></h1>
              <br></br>
              <p><b>Phone:{selectedMarker.description}</b></p>
            </div>
          </InfoWindow>
        )}  

      </GoogleMap>
    </LoadScript>
    </div>
  );
};

export default Home;
