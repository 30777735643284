import React, { useEffect, useState } from 'react';
import GoogleMap from './maps/gmap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-hot-toast";
import Header from './header/header';

const Registration = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  console.log("APIURL:"+apiUrl);

  const [address,SetAddress]=useState(null);
  const [position,SetPosition]=useState(null);
  const [userRegistration, registerNewUser] = useState(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    IsMaid:"",
    role:"",
    address:"",
    position:"",
    phonenumber:""
  });

  const [errors, setErrors] = useState({});

  const roles = [
    'Live-in Maid',
    'Live-out Maid',
    'Full-time Maid',
    'Part-time Maid',
    'Cleaning Maid',
    'Nanny',
    'Housekeeper',
    'House Manager',
    'Cook or Chef Maid',
    'Laundress',
    'Maternity Nurse',
    'Butler',
    'Chauffeur Maid',
    'Estate Manager',
    'Elder Care Maid',
    'Pet Care Maid',
  ];


  const handleChange = (e) => {
    const { name, value } = e.target;

    const div = document.querySelector('.roleDiv');

    if(name == "IsMaid" && e.target.checked)
      div.classList.remove('hidden');
    else if(name == "IsMaid" && !e.target.checked)
      div.classList.add('hidden');

    setFormData(({
      ...formData,
      [name]: value,
    }));
  };


  const validate = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = 'Full Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      newErrors.email = 'Invalid email address';
    }
    if (!formData.password) newErrors.password = 'Password is required';
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = 'Passwords do not match';
    if (!formData.role) newErrors.role = 'Role is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    setFormData(({
      ...formData,
      address: address,
      position:position,
    }));

  }, [address]);

  const handleSubmit = (e) => {
    e.preventDefault();
    //if (validate()) {
     
      const fetchData = async () => {
        try {
          const response = await axios.post(apiUrl+'/api/users/signup',formData);
          registerNewUser(response.data.message);  // Save the API response data

          // Reset form
          setFormData({
            fullName: '',
            email: '',
            password: '',
            role: '',
            IsMaid:'',
            address:'',
            position:'',
            phonenumber:''
          });
          
          setErrors({});

          toast.success("Registration Successful.");
          navigate('/login');
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();

      // Handle form submission, e.g., send data to API
      console.log('Form Data Submitted:', formData);

    //}
  };

  const receiveFromGMap = (value,position) =>{
    SetAddress(value);
    SetPosition(JSON.stringify(position));
  }

  return (
    <div>
      <Header></Header>
    <div className="flex items-center justify-center min-h-screen bg-base-200">
      <div className="card w-full max-w-md shadow-2xl bg-base-100 p-6">
        <h2 className="text-2xl font-bold text-center mb-6">Register</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Full Name */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Full Name</span>
            </label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter your full name"
              className={`input input-bordered ${
                errors.fullName ? 'input-error' : ''
              }`}
            />
            {errors.fullName && (
              <span className="text-red-500 text-sm">
                {errors.fullName}
              </span>
            )}
          </div>

          {/* Email */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className={`input input-bordered ${
                errors.email ? 'input-error' : ''
              }`}
            />
            {errors.email && (
              <span className="text-red-500 text-sm">{errors.email}</span>
            )}
          </div>

            {/* Phome Number */}
            <div className="form-control">
            <label className="label">
              <span className="label-text">Phone Number</span>
            </label>
            <input
              type="phone"
              name="phonenumber"
              value={formData.phonenumber}
              onChange={handleChange}
              placeholder="Enter your phone number"
              className={`input input-bordered ${
                errors.phonenumber ? 'input-error' : ''
              }`}
            />
            {errors.phonenumber && (
              <span className="text-red-500 text-sm">{errors.phonenumber}</span>
            )}
          </div>

          {/* Password */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Password</span>
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              className={`input input-bordered ${
                errors.password ? 'input-error' : ''
              }`}
            />
            {errors.password && (
              <span className="text-red-500 text-sm">
                {errors.password}
              </span>
            )}
          </div>


          <div className="mt-4">
              Are you a Maid? &nbsp;
            <input
              type="checkbox"
              name="IsMaid"
              onChange={handleChange}
              placeholder="Are you a Maid?"
            />
            {errors.IsMaid && (
              <span className="text-red-500 text-sm">
                {errors.IsMaid}
              </span>
            )}
          </div>

          {/* Role Selection */}
          <div className="form-control hidden roleDiv">
            <label className="label">
              <span className="label-text">Role</span>
            </label>
            <select
              name="role"
              value={formData.role}
              onChange={handleChange}
              className={`select select-bordered ${
                errors.role ? 'select-error' : ''
              }`}
            >
              {roles.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </select>
            {errors.role && (
              <span className="text-red-500 text-sm">{errors.role}</span>
            )}
          </div>

          <h3>Click on the map to select your communication address:</h3>
          <GoogleMap sendToRegistration={receiveFromGMap} />
          <label className="label">
            <span className="label-text" name="address">{address}</span>
          </label>
            
          {/* Submit Button */}
          <div className="form-control mt-6">
            <button type="submit" className="btn btn-primary">
              Register
            </button>
          </div>
        </form>

        {/* Link to Login */}
        <p className="text-center mt-4">
          Already have an account?{' '}
          <a href="/login" className="text-primary">
            Login here
          </a>
        </p>
      </div>
    </div>
    </div>
  );
};

export default Registration;
